import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import { MobileRegexp, loginValidate } from "@/hooks/component/loginValidate";
import { sendLoginVerifyCodeOld } from "@/apis";
import { trim } from "@/utils";
import { globalStorage } from "@/utils/storage/base";
export default defineComponent({
    name: "AuthRegister",
    components: {},
    setup() {
        const { currentRoute, push } = useRouter();
        const mobile = ref("");
        const isShow = ref(true);
        // 控制验证码下一步按钮是否可以点击
        const codeDisabled = computed(() => mobile.value !== "");
        // 控制验证码下一步按钮类型
        const codeBtnType = computed(() => codeDisabled.value ? "primary" : "default");
        // 微信code码
        const wechatcode = globalStorage.getFormLocal("login_wechat_code") ?? '';
        // 防止多次点击发送验证码
        let isSend = false;
        // 跳转验证码
        function jumpIdentifyCode() {
            push({
                name: "AuthIdentifyCode",
                query: {
                    mobile: trim(mobile.value),
                    from: "reset",
                    wechatcode
                },
            });
        }
        // 发送重置密码验证码
        function sendLoginVerify(params) {
            sendLoginVerifyCodeOld({
                mobile: trim(mobile.value),
                ...params,
            }).then((res) => {
                isShow.value = true;
                if (res.errcode === 0) {
                    Toast("验证码发送成功\n请注意手机短信");
                    // 设置持续存储timer
                    globalStorage.setToLocal("auto_login_timer", "60");
                    setTimeout(() => {
                        jumpIdentifyCode();
                    }, 500);
                }
                else if (res.errcode === 202400) {
                    isShow.value = false;
                    window.nvc.getNC({ renderTo: "registerNC" });
                }
                else {
                    Toast(res.errmsg || "系统异常");
                    initAwsc('login', sendLoginVerify);
                }
                isSend = false;
            });
        }
        // 发送验证码（该函数写到该页面，否则影响用户体验）
        async function sendCaptcha() {
            if (isSend)
                return;
            if (!MobileRegexp.test(trim(mobile.value))) {
                Toast("请填写正确的手机号码");
                return;
            }
            window.nvc.getNVCValAsync((nvcVal) => {
                isSend = true;
                sendLoginVerify({ captcha: nvcVal });
            });
        }
        const { initAwsc } = loginValidate();
        // 初始化
        initAwsc('login', sendLoginVerify);
        return {
            mobile,
            isShow,
            codeDisabled,
            codeBtnType,
            sendCaptcha,
        };
    },
});
