import { reactive } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import { globalStorage } from "@/utils/storage/base";
import { setAuthCookie, isWechat, jumpIndexUrl } from "@/utils";
import { getLoginVerifyConfig, getRegisterVerifyConfig } from "@/apis/index";
export const MobileRegexp = /^(((0\d{2,3}-?)?\d{7,8})|((\+?86-?)?1[3456789]\d{9}))$/;
export const PassRegexp = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}/;
export function loginValidate() {
    const { currentRoute, push } = useRouter();
    // 从 setup 返回的所有内容都将暴露给组件的其余部分。
    const formState = reactive({
        mobile: "",
        password: "",
        wechatcode: "",
        visible: true,
    });
    // form表单
    const form = reactive({
        mobile: "",
        password: "",
        code: "",
        wechatcode: ""
    });
    const validateMobile = async (rule, value) => {
        if (!value) {
            return Promise.reject("请输入手机号");
        }
        else if (MobileRegexp.test(value)) {
            return Promise.resolve();
        }
        else {
            return Promise.reject("请输入正确的手机号码");
        }
    };
    const validatePassword = async (rule, password) => {
        if (!password) {
            return Promise.reject("请输入密码");
        }
        else if (!PassRegexp.test(password)) {
            return Promise.reject("密码不正确，请重新输入");
        }
        else {
            return Promise.resolve();
        }
    };
    const rules = {
        mobile: [{ validator: validateMobile, trigger: "blur" }],
        password: [{ validator: validatePassword, trigger: "blur" }],
    };
    function handleInput(evt) {
        const value = evt.target.value;
        if (value.length < 12) {
            formState.mobile = value;
        }
    }
    function handlePassword(evt) {
        const value = evt.target.value;
        if (value.length < 17) {
            formState.password = value;
        }
    }
    // 跳转创建企业
    function jumpCorpCreate() {
        push({
            name: "AuthCorpCreate",
            query: {
                mobile: form.mobile,
                from: "login",
                wechatcode: form.wechatcode
            }
        });
    }
    // 处理多企业逻辑
    function handleLoginCorps(res) {
        // 清空code码 防止返回页面报失效
        globalStorage.setToLocal("login_wechat_code", "");
        const result = res.result;
        if (result.havecorps) {
            const corps = result.corps.map(item => ({
                corpname: item.Value,
                corpcode: item.Key
            })) || [];
            // 避免企业过多数据丢失、防止刷新页面数据丢失
            globalStorage.setToLocal("select_token", result.token);
            globalStorage.setToLocal("select_corps", JSON.stringify(corps));
            push({
                name: "AuthCorpSelect"
            });
        }
        else if (result.enginecode && result.token) {
            setAuthCookie(res, "");
            jumpIndexUrl(result.enginecode);
        }
        else {
            // 手机号未注册或已退出企业组织，请跳转注册企业
            jumpCorpCreate();
        }
    }
    // 初始化阿里人机验证
    async function initAwsc(type = "login", callback = null) {
        let config;
        if (type === "login") {
            config = await getLoginVerifyConfig();
        }
        else {
            config = await getRegisterVerifyConfig();
        }
        if (!config) {
            Toast("无痕验证配置加载失败");
            return;
        }
        // 实例化nvc 对无痕验证进行初始化操作
        if (document.getElementById("registerNC")) {
            document.getElementById("registerNC").innerHTML = "";
        }
        window.AWSC.use("nvc", function (state, module) {
            // 初始化 调用module.init进行初始化
            window.nvc = module.init({
                appkey: config.appkey,
                scene: config.scene,
                // 二次验证获取人机信息串，跟随业务请求一起上传至业务服务器，由业务服务器进行验签。
                success: function (data) {
                    if (typeof callback === 'function') {
                        const query = {
                            captcha: data
                        };
                        callback(query);
                    }
                },
                // 前端二次验证失败时触发该回调参数
                fail: function (failCode) {
                    Toast("验证失败");
                    console.warn("fail: ", failCode);
                },
                // 前端二次验证加载异常时触发该回调参数。
                error: function (errorCode) {
                    Toast("验证失败");
                    console.error("error: ", errorCode);
                },
            });
        });
    }
    ;
    // 解决微信浏览器的键盘回弹问题
    function inputSmooth() {
        if (isWechat) {
            const inputs = Array.from(document.getElementsByTagName('input'));
            const textareas = Array.from(document.getElementsByTagName('textarea'));
            const container = [...inputs, ...textareas];
            container.forEach(item => {
                item.onblur = () => {
                    document.getElementById('page').scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                };
            });
        }
    }
    return {
        formState,
        form,
        validateMobile,
        validatePassword,
        rules,
        initAwsc,
        handleInput,
        handlePassword,
        handleLoginCorps,
        inputSmooth,
    };
}
